<template>
	<div id="empresas">
		<div class="tabela-empresas">
			<div class="col-12 nova-empresa">
				<!-- botão para ativação da modal -->
				<v-btn class="primary-button" raised @click="dialog_empresa = true">
					<i class="fas fa-store"></i>
					<i class="mr-2 fas fa-plus-circle"></i> 
					Cadastrar Empresa
				</v-btn>
			</div>
			<!-- tabela que ira conter as informações de cada empresa -->
			<CustomTable 
				v-if="headers != ''"
				:action="'getEmpresas'"
				:getter="$store.getters.empresas"
				:headers="headers"
				:search="true"
				:title="'Empresas'"
				:icone="'fas fa-city'"
				:pagination="true"
				:filters="filtros"
				ref="tabela"
			>
				<template v-slot:cnpj="{ item }">
					{{ formatCnpjCpf(item.cnpj) }}
				</template>
				<template v-slot:status="{ item }">
					<span v-if="item.status">
						Ativo
						<img src="./../../assets/images/icon-ativo.png">
					</span>
					<span v-else>
						Inativo
						<img src="./../../assets/images/icon-inativo.png">
					</span>
				</template>
				<!-- botão para ativação da modal presente na tabela -->
				<template v-slot:acoes="{ item }">
					<v-btn class="primary-button" raised small @click="editarEmpresa(item.uuid)">
						<i class="fas fa-cog"></i>
						Editar
					</v-btn>
				</template>
			</CustomTable>
			<!-- modal para cadastro/edição de uma empresa -->
			<v-dialog v-model="dialog_empresa" persistent max-width="600px">
				<v-card>
					<v-card-title>
						<span v-if="empresa.empresa_uuid" class="headline"> <img src="./../../assets/images/icone-nova-empresa.png"> Editar Empresa</span>						
						<span v-else class="headline"><img src="./../../assets/images/icone-nova-empresa.png"> Nova Empresa</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-form ref="form_empresa">
								<div class="row">
									<div class="col-12">
										<v-text-field 
											:rules="[v => !!v || 'Campo Nome obrigatório']" 
											v-model="empresa.nome" 
											label="Nome" 
											placeholder="Nome" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
									<div class="col-12">
										<v-text-field 
											v-mask="'##.###.###/####-##'"
											type="text"
											:rules="[v => (validarCNPJ(v)) || 'Campo CNPJ obrigatório']" 
											v-model="empresa.cnpj" 
											label="CNPJ" 
											placeholder="CNPJ" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
									<div v-if="empresa.empresa_uuid" class="col-12">
										<v-text-field 
											:rules="[v => !!v || 'Campo Token obrigatório']" 
											v-model="empresa.token" 
											label="Token" 
											placeholder="Token" 
											background-color="white"
											hide-details
											outlined
											disabled
										/>
									</div>
									<div v-if="empresa.empresa_uuid" class="col-12">
										<v-select 
											:rules="[v => !!v || 'Campo Status obrigatório']" 
											:items="['Ativo', 'Inativo']" 
											v-model="empresa.status" 
											label="Status" 
											placeholder="Status" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
								</div>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeempresa">
							Cancelar
						</v-btn>
						<v-btn class="primary-button" small @click="enviarempresa">
							Salvar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
			<Loader v-if="loading"/>
		</div>
	</div>
</template>
<!--funções para trazer e encaminhar os dados da modal e da tabela-->
<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'

	// exporta o componente
	export default {
		// nome do componente
		name: 'empresas',
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			// variável para mostrar a modal para editar/criar um empresa
			dialog_empresa: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			// variável para criar/editar empresa
			empresa: {
				empresa_uuid: '',
				nome: '',
				cnpj: '',
				status: '',
				token: '',
				token_ok_whats: '',
			},
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'name',
					text: 'Nome',
					sortable: true,
				},
				{
					value: 'cnpj',
					text: 'CNPJ',
					sortable: true,
				},
				{
					value: 'token',
					text: 'Token',
					sortable: true,
				},
				{
					value: 'status',
					text: 'Status',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			// variável para os filtros da tabela
			filtros: {
				perPage: 20,
			},
		}),
		// funções deste componente
		methods: {
			// função para enviar um empresa
			async enviarempresa(){
				let resp = {}
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_empresa.validate()){
					// coloca o componente como loading
					this.loading = true
					// coloeta os dados do empresa
					let dados = {
						// coleta o nome do empresa
						name: this.empresa.nome,
						// coleta o cnpj do empresa
						cnpj: this.empresa.cnpj ? this.empresa.cnpj.replace(/\D/g, "") : '',
						// coleta o token para o okWhats da empresa
						token_okwhats: this.empresa.token_ok_whats == '' ? null : this.empresa.token_ok_whats,
						// coleta o status da empresa
						status: true,
						// coleta a cinfiguração de privacidade do whats
						privacy_whatsapp: this.empresa.privacy_whatsapp == 'Ativo' ? true : false
					}
					// caso exista uma empresa_uuid 
					if(this.empresa.empresa_uuid){
						// coleta o status do empresa
						dados.status = this.empresa.status == 'Ativo' ? true : false
						// armazena os dados para realizar a atualização
						let date_update = {
							dados_empresa: dados,
							uuid: this.empresa.empresa_uuid
						}
						// rota para a atualização dos dados da empresa
						resp = await store.dispatch('putEmpresas', date_update)
					}else{
						// faz a criação dos dados da empresa
						resp = await store.dispatch('postEmpresas', dados)
					}
					// caso algo tenha dado errado
					if(resp.status != 200 && resp.status != 201){
						// atribui o título da mensagem 
						this.resposta.titulo = 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado tudo certo
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = 'Empresa '  +  (this.empresa.empresa_uuid ? 'editada!' : 'cadastrada!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = 'Empresa ' + (this.empresa.empresa_uuid ? 'editada' : 'cadastrada') + ' com sucesso!'
						// fecha a modal de create/edit empresa
						this.closeempresa()
						// mostra a mensagem
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para coletar um empresa para editar
			async editarEmpresa(empresa_uuid){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar uma empresa passando o uuid 
				var resp = await store.dispatch('getEmpresa', empresa_uuid)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados do empresa vindos do back à váriável local
					this.empresa.empresa_uuid = await resp.data.company.uuid || ''
					this.empresa.nome = await resp.data.company.name || ''
					this.empresa.cnpj = await resp.data.company.cnpj || '',
					this.empresa.status = await resp.data.company.status == true ? 'Ativo' : 'Inativo'
					this.empresa.token = await resp.data.company.token || ''
					this.empresa.token_ok_whats = await resp.data.company.token_okwhats || ''
					// mostra a modal de creat/edit empresa
					this.dialog_empresa = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função que roda quando é fechada a modal de create/edit empresa
			closeempresa(){
				// fecha a modal
				this.dialog_empresa = false
				// limpa os dados locais do empresa
				this.empresa =  {
					empresa_uuid: '',
					nome: '',
					cnpj: '',
					status: '',
					token: '',
					token_ok_whats: '',
				}
			},
			// função para formatar a identificação da empresa
			formatCnpjCpf(value){
				if(value){
					const cnpjCpf = value.replace(/\D/g, '');
				
					return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
				}else{
					return  ''
				}
			},
			// função para fazer as validações da identificação da empresa
			validarCNPJ(value) {
				var cnpj = value.replace(/[^\d]+/g,'');
				if(cnpj == '') return false;
				if (cnpj.length != 14){
					return false;
				}
				// Elimina CNPJs invalidos conhecidos
				if (cnpj == "00000000000000" || 
					cnpj == "11111111111111" || 
					cnpj == "22222222222222" || 
					cnpj == "33333333333333" || 
					cnpj == "44444444444444" || 
					cnpj == "55555555555555" || 
					cnpj == "66666666666666" || 
					cnpj == "77777777777777" || 
					cnpj == "88888888888888" || 
					cnpj == "99999999999999"){
					return false;
				}
				// Valida DVs
				var tamanho = cnpj.length - 2
				var numeros = cnpj.substring(0,tamanho);
				var digitos = cnpj.substring(tamanho);
				var soma = 0;
				var pos = tamanho - 7;
				for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
						pos = 9;
				}
				resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
				if (resultado != digitos.charAt(0))
					return false;
					
				tamanho = tamanho + 1;
				numeros = cnpj.substring(0,tamanho);
				soma = 0;
				pos = tamanho - 7;
				for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
						pos = 9;
				}
				var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
				if (resultado != digitos.charAt(1))
					return false;
					
				return true
			},
		},
	}
</script>
<!-- scss do front -->
<style lang="scss" scoped>
	#empresas{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;
		.tabela-empresas{
			width: 100%;
			background-color: #fff;
			border-radius: 10px;
			.nova-empresa{
				display: flex;
				justify-content: flex-end;
				padding-bottom: 0;
				button{
					padding: 0 5px !important;
					i, svg{
						margin-left: 10px;
					}
					text-transform: capitalize;
				}
			}
		}
	}
	.v-dialog{
		.v-card{
			background-color: #f9f9f9;
		}
		.headline{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-weight: 600;
			color: #11263C;
		}
	}
</style>